
.miniHomeBash {
    background:black;
    color:#bbbbbb;
    width:100%;
    
    padding: 5px;
    border-bottom-left-radius:0.5rem;
    border-bottom-right-radius:0.5rem;
    }
    .miniHomeBash  input{
    width:100%;
    display: inline-block;
    background:black;
    color:#bbbbbb;
    padding:12;
    margin:0px 0px 0px 10px;
    direction: ltf;
    }
    label {
    float:left;
    }
    .bash button {
    float:right;
    }
    .extra {
        margin-bottom:0!important;
        padding:0;
         margin:auto; 
         border:0;
         border-radius: 0;
    }
    .floatRight {
        float:right;
    }
    .floatLeft {
        float:left;
    }
    .top-radius {
        border-top-left-radius:0.5rem;
        border-top-right-radius:0.5rem;
    }
    .padding0 {
        padding:0;
    }
    .miniPre::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      .miniPre::-webkit-scrollbar-track {
        background: #a3a3a3; 
        width: 11px;
      }
       
      /* Handle */
      .miniPre::-webkit-scrollbar-thumb {
        background: rgb(204, 204, 204); 
        width: 11px;
      }
      
      /* Handle on hover */
      .miniPre::-webkit-scrollbar-thumb:hover {
        background: rgb(173, 173, 173); 
      }
    .miniPre {
        color:#bbbbbb;
        text-align: left;
        font-size:0.9em;
        /*height:455px;*/
        white-space: pre-wrap; 
        padding-left:0.1em;
        scrollbar-color: black black;


    }
    .miniPre::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.63) ;
        border-radius: 6px;
        /*border: 3px solid var(--scrollbarBG);*/
      }
    @media only screen and (max-width: 400px) {
        .myheader {
            padding-top:5px !important;
            height:30px !important;
        }
    }
    .myheader {
        background-color: #525252;
        height:40px;
    }
    .mini_flex_space_between {
        display:flex;
        justify-content: space-between;
    bottom: 0;
    position:absolute;
    width: 90%;
    padding-bottom: 0.5em;
    }
    .tab {
        background:rgb(0, 0, 0);
        color:white;
        width:100%;
     
        font-size:0.8em;
        }
.onetab {
            background:#2B2B2B;
            color:#bbbbbb;
            width:200px;
            margin-left:100px;
            padding: auto;
            text-align: center;
            font-size:1.3em;
            }

.buttons {
    background:#2B2B2B;
    position:relative;
    height: max-content;
    text-align: center;
    padding:auto;
    padding-bottom:0.5em;
}
.node {
    text-align:left;
    font-size: 1.1em;
}
.node textarea {
    width:90%;
    height:50vh;
    display: inline-block;
    background:black;
    color:#bbbbbb;
    margin:0px 0px 0px 0px;
    font-size: 0.8em;
    text-align:left;
    }
.selectLang {

    color: #1aa0af;
    background: #2b2b2b;
    margin-left:0.5em;
    border-color:#1aa0af;
    }
.selectLang select option {

    color: #1aa0af;
    background: #2b2b2b;
    }
    .preBoxTitle {
        background: rgb(54 54 54);
        font-weight: 500;
        display: flex;
        justify-content: center;
        font-weight: 600;
        /* padding-left: 3em; */
    }
.interview_in_bash {
    color:orange;
}
pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
   }