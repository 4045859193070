.f16 .flag{display:inline-block;height:16px;width:16px;vertical-align:text-top;line-height:16px;background:url(./flags16.png) no-repeat;}
.f16 ._African_Union{background-position:0 -16px;}
.f16 ._Arab_League{background-position:0 -32px;}
.f16 ._ASEAN{background-position:0 -48px;}
.f16 ._CARICOM{background-position:0 -64px;}
.f16 ._CIS{background-position:0 -80px;}
.f16 ._Commonwealth{background-position:0 -96px;}
.f16 ._England{background-position:0 -112px;}
.f16 ._European_Union, .f16 .eu{background-position:0 -128px;}
.f16 ._Islamic_Conference{background-position:0 -144px;}
.f16 ._Kosovo{background-position:0 -160px;}
.f16 ._NATO{background-position:0 -176px;}
.f16 ._Northern_Cyprus{background-position:0 -192px;}
.f16 ._Northern_Ireland{background-position:0 -208px;}
.f16 ._Olimpic_Movement{background-position:0 -224px;}
.f16 ._OPEC{background-position:0 -240px;}
.f16 ._Red_Cross{background-position:0 -256px;}
.f16 ._Scotland{background-position:0 -272px;}
.f16 ._Somaliland{background-position:0 -288px;}
.f16 ._Tibet{background-position:0 -304px;}
.f16 ._United_Nations{background-position:0 -320px;}
.f16 ._Wales{background-position:0 -336px;}
.f16 .ad, .f16 .and {background-position: 0 -704px;}
.f16 .ae, .f16 .are {background-position: 0 -736px;}
.f16 .af, .f16 .afg {background-position: 0 -768px;}
.f16 .ag, .f16 .atg {background-position: 0 -800px;}
.f16 .ai, .f16 .aia {background-position: 0 -832px;}
.f16 .al, .f16 .alb {background-position: 0 -864px;}
.f16 .am, .f16 .arm {background-position: 0 -896px;}
.f16 .ao, .f16 .ago {background-position: 0 -928px;}
.f16 .aq, .f16 .ata {background-position: 0 -960px;}
.f16 .ar, .f16 .arg {background-position: 0 -992px;}
.f16 .as, .f16 .asm {background-position: 0 -1024px;}
.f16 .at, .f16 .aut {background-position: 0 -1056px;}
.f16 .au, .f16 .aus {background-position: 0 -1088px;}
.f16 .aw, .f16 .abw {background-position: 0 -1120px;}
.f16 .ax, .f16 .ala {background-position: 0 -1152px;}
.f16 .az, .f16 .aze {background-position: 0 -1184px;}
.f16 .ba, .f16 .bih {background-position: 0 -1216px;}
.f16 .bb, .f16 .brb {background-position: 0 -1248px;}
.f16 .bd, .f16 .bgd {background-position: 0 -1280px;}
.f16 .be, .f16 .bel {background-position: 0 -1312px;}
.f16 .bf, .f16 .bfa {background-position: 0 -1344px;}
.f16 .bg, .f16 .bgr {background-position: 0 -1376px;}
.f16 .bh, .f16 .bhr {background-position: 0 -1408px;}
.f16 .bi, .f16 .bdi {background-position: 0 -1440px;}
.f16 .bj, .f16 .ben {background-position: 0 -1472px;}
.f16 .bm, .f16 .bmu {background-position: 0 -1504px;}
.f16 .bn, .f16 .brn {background-position: 0 -1536px;}
.f16 .bo, .f16 .bol {background-position: 0 -1568px;}
.f16 .br, .f16 .bra {background-position: 0 -1600px;}
.f16 .bs, .f16 .bhs {background-position: 0 -1632px;}
.f16 .bt, .f16 .btn {background-position: 0 -1664px;}
.f16 .bw, .f16 .bwa {background-position: 0 -1696px;}
.f16 .by, .f16 .blr {background-position: 0 -1728px;}
.f16 .bz, .f16 .blz {background-position: 0 -1760px;}
.f16 .ca, .f16 .can {background-position: 0 -1792px;}
.f16 .cd, .f16 .cod {background-position: 0 -1824px;}
.f16 .cf, .f16 .rca {background-position: 0 -1856px;}
.f16 .cg, .f16 .cog {background-position: 0 -1888px;}
.f16 .ch, .f16 .che {background-position: 0 -1920px;}
.f16 .ci, .f16 .civ {background-position: 0 -1952px;}
.f16 .ck, .f16 .cok {background-position: 0 -1984px;}
.f16 .cl, .f16 .chl {background-position: 0 -2016px;}
.f16 .cm, .f16 .cmr {background-position: 0 -2048px;}
.f16 .cn, .f16 .chn {background-position: 0 -2080px;}
.f16 .co, .f16 .col {background-position: 0 -2112px;}
.f16 .cr, .f16 .cri {background-position: 0 -2144px;}
.f16 .cu, .f16 .cub {background-position: 0 -2176px;}
.f16 .cv, .f16 .cpv {background-position: 0 -2208px;}
.f16 .cy, .f16 .cyp {background-position: 0 -2240px;}
.f16 .cz, .f16 .cze {background-position: 0 -2272px;}
.f16 .de, .f16 .deu {background-position: 0 -2304px;}
.f16 .dj, .f16 .dji {background-position: 0 -2336px;}
.f16 .dk, .f16 .dnk {background-position: 0 -2368px;}
.f16 .dm, .f16 .dma {background-position: 0 -2400px;}
.f16 .do, .f16 .dom {background-position: 0 -2432px;}
.f16 .dz, .f16 .dza {background-position: 0 -2464px;}
.f16 .ec, .f16 .ecu {background-position: 0 -2496px;}
.f16 .ee, .f16 .est {background-position: 0 -2528px;}
.f16 .eg, .f16 .egy {background-position: 0 -2560px;}
.f16 .eh, .f16 .esh {background-position: 0 -2592px;}
.f16 .er, .f16 .eri {background-position: 0 -2624px;}
.f16 .es, .f16 .esp {background-position: 0 -2656px;}
.f16 .et, .f16 .eth {background-position: 0 -2688px;}
.f16 .fi, .f16 .fin {background-position: 0 -2720px;}
.f16 .fj, .f16 .fji {background-position: 0 -2752px;}
.f16 .fm, .f16 .fsm {background-position: 0 -2784px;}
.f16 .fo, .f16 .fro {background-position: 0 -2816px;}
.f16 .fr, .f16 .fra {background-position: 0 -2848px;}
.f16 .bl, .f16 .blm {background-position: 0 -2848px;}
.f16 .cp, .f16 .cpt {background-position: 0 -2848px;}
.f16 .mf, .f16 .maf {background-position: 0 -2848px;}
.f16 .yt, .f16 .myt {background-position: 0 -2848px;}
.f16 .ga, .f16 .gab {background-position: 0 -2880px;}
.f16 .gb, .f16 .gbr {background-position: 0 -2912px;}
.f16 .sh, .f16 .shn {background-position: 0 -2912px;}
.f16 .gd, .f16 .grd {background-position: 0 -2944px;}
.f16 .ge, .f16 .geo {background-position: 0 -2976px;}
.f16 .gg, .f16 .ggy {background-position: 0 -3008px;}
.f16 .gh, .f16 .gha {background-position: 0 -3040px;}
.f16 .gi, .f16 .gib {background-position: 0 -3072px;}
.f16 .gl, .f16 .grl {background-position: 0 -3104px;}
.f16 .gm, .f16 .gmb {background-position: 0 -3136px;}
.f16 .gn, .f16 .gin {background-position: 0 -3168px;}
.f16 .gp, .f16 .glp {background-position: 0 -3200px;}
.f16 .gq, .f16 .gnq {background-position: 0 -3232px;}
.f16 .gr, .f16 .grc {background-position: 0 -3264px;}
.f16 .gt, .f16 .gtm {background-position: 0 -3296px;}
.f16 .gu, .f16 .gum {background-position: 0 -3328px;}
.f16 .gw, .f16 .gnb {background-position: 0 -3360px;}
.f16 .gy, .f16 .guy {background-position: 0 -3392px;}
.f16 .hk, .f16 .hkg {background-position: 0 -3424px;}
.f16 .hn, .f16 .hnd {background-position: 0 -3456px;}
.f16 .hr, .f16 .hrv {background-position: 0 -3488px;}
.f16 .ht, .f16 .hti {background-position: 0 -3520px;}
.f16 .hu, .f16 .hun {background-position: 0 -3552px;}
.f16 .id, .f16 .idn {background-position: 0 -3584px;}
.f16 .mc, .f16 .mco {background-position: 0 -3584px;}
.f16 .ie {background-position: 0 -3616px;}
.f16 .il, .f16 .isr {background-position: 0 -3648px;}
.f16 .im, .f16 .imn {background-position: 0 -3680px;}
.f16 .in, .f16 .ind {background-position: 0 -3712px;}
.f16 .iq, .f16 .irq {background-position: 0 -3744px;}
.f16 .ir, .f16 .irn {background-position: 0 -3776px;}
.f16 .is, .f16 .isl {background-position: 0 -3808px;}
.f16 .it, .f16 .ita {background-position: 0 -3840px;}
.f16 .je, .f16 .jey {background-position: 0 -3872px;}
.f16 .jm, .f16 .jam {background-position: 0 -3904px;}
.f16 .jo, .f16 .jor {background-position: 0 -3936px;}
.f16 .jp, .f16 .jpn {background-position: 0 -3968px;}
.f16 .ke, .f16 .ken {background-position: 0 -4000px;}
.f16 .kg, .f16 .kgz {background-position: 0 -4032px;}
.f16 .kh, .f16 .khm {background-position: 0 -4064px;}
.f16 .ki, .f16 .kir {background-position: 0 -4096px;}
.f16 .km, .f16 .com {background-position: 0 -4128px;}
.f16 .kn, .f16 .kna {background-position: 0 -4160px;}
.f16 .kp, .f16 .prk {background-position: 0 -4192px;}
.f16 .kr, .f16 .kor {background-position: 0 -4224px;}
.f16 .kw, .f16 .kwt {background-position: 0 -4256px;}
.f16 .ky, .f16 .cym {background-position: 0 -4288px;}
.f16 .kz, .f16 .kaz {background-position: 0 -4320px;}
.f16 .la, .f16 .lao {background-position: 0 -4352px;}
.f16 .lb, .f16 .lbn {background-position: 0 -4384px;}
.f16 .lc, .f16 .lca {background-position: 0 -4416px;}
.f16 .li, .f16 .lie {background-position: 0 -4448px;}
.f16 .lk, .f16 .lka {background-position: 0 -4480px;}
.f16 .lr, .f16 .lbr {background-position: 0 -4512px;}
.f16 .ls, .f16 .lso {background-position: 0 -4544px;}
.f16 .lt, .f16 .ltu {background-position: 0 -4576px;}
.f16 .lu, .f16 .lux {background-position: 0 -4608px;}
.f16 .lv, .f16 .lva {background-position: 0 -4640px;}
.f16 .ly, .f16 .lby {background-position: 0 -4672px;}
.f16 .ma, .f16 .mar {background-position: 0 -4704px;}
.f16 .md, .f16 .mda {background-position: 0 -4736px;}
.f16 .me, .f16 .mne {background-position: 0 -4768px;}
.f16 .mg, .f16 .mdg {background-position: 0 -4800px;}
.f16 .mh, .f16 .mhl {background-position: 0 -4832px;}
.f16 .mk, .f16 .mkd {background-position: 0 -4864px;}
.f16 .ml, .f16 .mli {background-position: 0 -4896px;}
.f16 .mm, .f16 .mmr {background-position: 0 -4928px;}
.f16 .mn, .f16 .mng {background-position: 0 -4960px;}
.f16 .mo, .f16 .mac {background-position: 0 -4992px;}
.f16 .mq, .f16 .mtq {background-position: 0 -5024px;}
.f16 .mr, .f16 .mrt {background-position: 0 -5056px;}
.f16 .ms, .f16 .msr {background-position: 0 -5088px;}
.f16 .mt, .f16 .mlt {background-position: 0 -5120px;}
.f16 .mu, .f16 .mus {background-position: 0 -5152px;}
.f16 .mv, .f16 .mdv {background-position: 0 -5184px;}
.f16 .mw, .f16 .mwi {background-position: 0 -5216px;}
.f16 .mx, .f16 .mex {background-position: 0 -5248px;}
.f16 .my, .f16 .mys {background-position: 0 -5280px;}
.f16 .mz, .f16 .moz {background-position: 0 -5312px;}
.f16 .na, .f16 .nam {background-position: 0 -5344px;}
.f16 .nc, .f16 .ncl {background-position: 0 -5376px;}
.f16 .ne, .f16 .ner {background-position: 0 -5408px;}
.f16 .ng, .f16 .nga {background-position: 0 -5440px;}
.f16 .ni, .f16 .nic {background-position: 0 -5472px;}
.f16 .nl, .f16 .nld {background-position: 0 -5504px;}
.f16 .nl{background-position:0 -5504px;}  .f16 .bq{background-position:0 -5504px;}.f16 .no{background-position:0 -5536px;}  .f16 .bv, .f16 .nq, .f16 .sj{background-position:0 -5536px;}
.f16 .bq, .f16 .bes {background-position: 0 -5504px;}
.f16 .no, .f16 .nor {background-position: 0 -5536px;}
.f16 .bv, .f16 .bvt {background-position: 0 -5536px;}
.f16 .nq, .f16 .atn {background-position: 0 -5536px;}
.f16 .sj, .f16 .sjm {background-position: 0 -5536px;}
.f16 .np, .f16 .npl {background-position: 0 -5568px;}
.f16 .nr, .f16 .nru {background-position: 0 -5600px;}
.f16 .nz, .f16 .nzl {background-position: 0 -5632px;}
.f16 .om, .f16 .omn {background-position: 0 -5664px;}
.f16 .pa, .f16 .pan {background-position: 0 -5696px;}
.f16 .pe, .f16 .per {background-position: 0 -5728px;}
.f16 .pf, .f16 .pyf {background-position: 0 -5760px;}
.f16 .pg, .f16 .png {background-position: 0 -5792px;}
.f16 .ph, .f16 .phl {background-position: 0 -5824px;}
.f16 .pk, .f16 .pak {background-position: 0 -5856px;}
.f16 .pl, .f16 .pol {background-position: 0 -5888px;}
.f16 .pr, .f16 .pri {background-position: 0 -5920px;}
.f16 .ps, .f16 .pse {background-position: 0 -5952px;}
.f16 .pt, .f16 .prt {background-position: 0 -5984px;}
.f16 .pw, .f16 .plw {background-position: 0 -6016px;}
.f16 .py, .f16 .pry {background-position: 0 -6048px;}
.f16 .qa, .f16 .qat {background-position: 0 -6080px;}
.f16 .re, .f16 .reu {background-position: 0 -6112px;}
.f16 .ro, .f16 .rou {background-position: 0 -6144px;}
.f16 .rs, .f16 .srb {background-position: 0 -6176px;}
.f16 .ru, .f16 .rus {background-position: 0 -6208px;}
.f16 .rw, .f16 .rwa {background-position: 0 -6240px;}
.f16 .sa, .f16 .sau {background-position: 0 -6272px;}
.f16 .sb, .f16 .slb {background-position: 0 -6304px;}
.f16 .sc, .f16 .syc {background-position: 0 -6336px;}
.f16 .sd, .f16 .sdn {background-position: 0 -6368px;}
.f16 .se, .f16 .swe {background-position: 0 -6400px;}
.f16 .sg, .f16 .sgp {background-position: 0 -6432px;}
.f16 .si, .f16 .svn {background-position: 0 -6464px;}
.f16 .sk, .f16 .svk {background-position: 0 -6496px;}
.f16 .sl, .f16 .sle {background-position: 0 -6528px;}
.f16 .sm, .f16 .smr {background-position: 0 -6560px;}
.f16 .sn, .f16 .sen {background-position: 0 -6592px;}
.f16 .so, .f16 .som {background-position: 0 -6624px;}
.f16 .sr, .f16 .sur {background-position: 0 -6656px;}
.f16 .st, .f16 .stp {background-position: 0 -6688px;}
.f16 .sv, .f16 .slv {background-position: 0 -6720px;}
.f16 .sy, .f16 .syr {background-position: 0 -6752px;}
.f16 .sz, .f16 .swz {background-position: 0 -6784px;}
.f16 .tc, .f16 .tca {background-position: 0 -6816px;}
.f16 .td, .f16 .tcd {background-position: 0 -6848px;}
.f16 .tg, .f16 .tgo {background-position: 0 -6880px;}
.f16 .th, .f16 .tha {background-position: 0 -6912px;}
.f16 .tj, .f16 .tjk {background-position: 0 -6944px;}
.f16 .tl, .f16 .tls {background-position: 0 -6976px;}
.f16 .tm, .f16 .tkm {background-position: 0 -7008px;}
.f16 .tn, .f16 .tun {background-position: 0 -7040px;}
.f16 .to, .f16 .ton {background-position: 0 -7072px;}
.f16 .tr, .f16 .tur {background-position: 0 -7104px;}
.f16 .tt, .f16 .tto {background-position: 0 -7136px;}
.f16 .tv, .f16 .tuv {background-position: 0 -7168px;}
.f16 .tw, .f16 .twn {background-position: 0 -7200px;}
.f16 .tz, .f16 .tza {background-position: 0 -7232px;}
.f16 .ua, .f16 .ukr {background-position: 0 -7264px;}
.f16 .ug, .f16 .uga {background-position: 0 -7296px;}
.f16 .us, .f16 .usa {background-position: 0 -7328px;}
.f16 .uy, .f16 .ury {background-position: 0 -7360px;}
.f16 .uz, .f16 .uzb {background-position: 0 -7392px;}
.f16 .va, .f16 .vat {background-position: 0 -7424px;}
.f16 .vc, .f16 .vct {background-position: 0 -7456px;}
.f16 .ve, .f16 .ven {background-position: 0 -7488px;}
.f16 .vg, .f16 .vgb {background-position: 0 -7520px;}
.f16 .vi, .f16 .vir {background-position: 0 -7552px;}
.f16 .vn, .f16 .vnm {background-position: 0 -7584px;}
.f16 .vu, .f16 .vut {background-position: 0 -7616px;}
.f16 .ws, .f16 .wsm {background-position: 0 -7648px;}
.f16 .ye, .f16 .yem {background-position: 0 -7680px;}
.f16 .za, .f16 .zaf {background-position: 0 -7712px;}
.f16 .zm, .f16 .zmb {background-position: 0 -7744px;}
.f16 .zw, .f16 .zwe {background-position: 0 -7776px;}
.f16 .sx, .f16 .sxm {background-position: 0 -7808px;}
.f16 .cw, .f16 .cuw {background-position: 0 -7840px;}
.f16 .ss, .f16 .ssd {background-position: 0 -7872px;}
.f16 .nu, .f16 .niu {background-position: 0 -7904px;}