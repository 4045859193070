.challengeList {

    border-radius: 4px;

    
}
.challengeListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eeeeee;
    padding-left:1em;
    padding-right:1em;
    padding-top: 1em;
    padding-bottom: 1em;  
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background:white;
    box-shadow: 2px 4px 20px 6px hsl(0deg 0% 57% / 10%);
    
}
.green {
    background:rgb(225, 255, 216);
}
.challengeInfo {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:flex-start;
}
.challengeInfoLink {
    font-size: 14px;
    font-weight: 600;

}
.challengeInfoList {
    font-size: 12px;
    font-weight: 400;
 
    align-items: center;
}
.playCircle {
    width: 10px;
    height: 10px;
    background-color: #72ff00;
    border-radius: 50%;
    animation-name: pulsate;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite; 
    margin-left: 1em;
    margin-top: 2px;

}


@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
@keyframes pulsate {
    0% { opacity: 0.6;}
    50% {opacity: 1.0;}
    100% {opacity: 0.6;}
}
.lastActiveTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;

    padding:2em 2em;
    border:none;
    font-size: 0.95em;
    margin: auto;
    font-size: 1.1em;
  }
  .lastActiveTable td {
      padding: 5px 15px;
  }
  .miniBox {
    box-shadow: -1px -1px 16px 3px rgb(211, 211, 211);
    
    margin-top: 2em;
    margin-left:1em;
    margin-right:1em;
    padding:0;
    
   

}
.miniBox2 {
    box-shadow: -1px -1px 16px 3px #7e7e7e;
    

    margin-left:1em;
    margin-right:1em;
    padding:0;
    
   

}
.miniBoxTitle {
    background:rgb(233, 233, 233);
    font-weight: 500;
    display:flex;
    justify-content: center;
}



/*
body {
    color: #1a1a1a;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1.6em;
    text-align: center;
    background: #757f9a;
    background: -webkit-linear-gradient(to left, #d7dde8, #757f9a);
    background: linear-gradient(to left, #d7dde8, #757f9a);
  }
  */
  #content {
    max-width: 800px;
    
  }
  #content2 {
    position: relative;
    left:6em;
    top:-24.5em;
 
    padding-left: 10%;
 
    
  }

  .testimonial blockquote {
    margin: 10px 10px 0;
    background: #c8e0e3;
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
  }
  
  .testimonial blockquote:before,
  .testimonial blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 80px;
    line-height: 1;
    color: #757f9a;
    font-style: normal;
  }
  
  .testimonial blockquote:before {
    top: 0;
    left: 10px;
  }
  .rightTestimonial blockquote:before {
    top: 0;
    left: 10px !important;
    font-size:50px !important;
  }
  .rightTestimonial blockquote:after {
    font-size:50px !important;
  }
  .rightTestimonial blockquote {
    padding: 7px 50px;
    margin: 10px 23px 0;
    color: #434b52
  }
  .rightTestimonial div {
    margin: 0 0 0 208px !important;
  }
  .rightTestimonial p {
    font-size:13px !important;
    margin: 0px 0 0 182px !important;
  }
  
  
  .testimonial blockquote:after {
    content: "\201D";
    right: 10px;
    bottom: -0.5em;
  }
  
  .testimonial div {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #c8e0e3;
    margin: 0 0 0 60px;
  }
  
  .testimonial p {
    margin: 8px 0 0 20px;
    text-align: left;

    font-size: 15px;
    line-height: 1.6em;
    color:#3a3a3a;
  }
  .section {
    margin-left:1em;
    margin-right:1em;
    margin-bottom: 2em;
    margin-top:-0.5em;
  }
  .innerSecion {
    //background:#56A3A6;
    background:#14B8A6;
    width:100%;
    text-align:left;
    margin: 0 auto;
    height: 250px;
    border-radius: 8px;
    box-shadow: -1px -1px 16px 3px rgb(211, 211, 211);
  }
  .textAndImage {
    //background:#56A3A6;
    background:#14B8A6;
    width:100%;
    text-align:left;
    margin: 0 auto;

    display: flex;

    justify-content: space-between;
    height: 250px;
    max-width:1300px;
    padding-left:4%;
    padding-right:4%;
    padding-top:2em;
    border-radius: 8px;

  }
  .rightImage {
    margin-top: -15px;
  }
  .rightImage img{
    filter: drop-shadow(2.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }
  .rightImageImage {
    /*filter: drop-shadow(12rem,17.2rem,29.25rem,rgba(33, 0, 0, 3.2));*/
  }
  .textSection h1 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.8em;
    color: #fff;
  }
  .textSection h2 {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    letter-spacing: 1.1px;
    font-size: 1.5em;
    color: #fff;
  }
  .textSection h3 {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1.1px;
    font-weight: 400;
    color: #FFF;
    opacity: 0.85;
}

@media only screen and (max-width: 1450px) {
  .rightImage {
    display:none;
  }
  .innerSecion {
    height:100%;
    text-align:center;
    width:100%;
  }
  .textAndImage {
    height:100%;
    text-align:center;
    width:100%;
  }
  #content2 {
    display:none;
  }

}
@media only screen and (max-width: 500px) {
  .rightImage {
    display:none;
  }
  .innerSecion {
    height:100%;
    text-align:center;
    width:97%;
  }
  .textAndImage {
    height:100%;
    text-align:center;
    width:97%;
  }
  #content2 {
    display:none;
  }

}

.startButton {
  background-color: #007bff; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.startButton:hover{
  background-color: #0b57a8; /* Green */
  color: white;
  text-decoration: none;

}