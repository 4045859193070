.svgCircle {
    margin: auto auto 0px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    width: 50px;
    height: 50px;
    justify-content: center;
    color: rgb(3, 3, 3);
    font-size:2em;
    background-image: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgba(212, 212, 212, 0.712) 100%);
}