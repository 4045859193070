@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.logo {
    width:170px;
    /*filter:invert(100%);*/
}
.appBar {
    background-color: black;;
}
.smallSceenError {
    display: none;
}
@media only screen and (max-width: 400px) {
    .smallSceenError {
        display:block;;
        position:block;
        top:0;
        left:0;
        bottom: 0;
        right:0;
        margin-bottom: 20em;
        padding:auto;
    }

  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /*padding: 1rem;*/
  }
  .loginRegisterLink {

    
    font-weight: 500;
    color: #000000;

  }